export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~4],
		"/_dbd": [21,[3]],
		"/_dbd/asset0": [24,[3]],
		"/_dbd/asset0/[action]": [~25,[3]],
		"/_dbd/asset": [~22,[3]],
		"/_dbd/asset/_tobe_del_edit/[[id]]": [~23,[3]],
		"/_dbd/login": [~26,[3]],
		"/_dbd/logout": [~27,[3]],
		"/_dbd/page": [~28,[3]],
		"/_dbd/page/edit/[[id]]": [~29,[3]],
		"/_dbd/post": [~30,[3]],
		"/_dbd/post/edit/[[entry_id]]": [~31,[3]],
		"/_dbd/tag": [~32,[3]],
		"/(raw)/floats/ref": [5],
		"/(raw)/floats/snapshot": [6],
		"/(web)/gallery": [14,[2]],
		"/(raw)/glb-viewer/[[id]]": [~7],
		"/(raw)/minpdf": [8],
		"/(raw)/minpdf/viewer": [9],
		"/(web)/page/[key]/[[params]]": [~15,[2]],
		"/(web)/post/[entry_id]": [~16,[2]],
		"/(web)/privacy-policy": [17,[2]],
		"/(web)/termsof-service": [18,[2]],
		"/(raw)/test-shader/0": [10],
		"/(raw)/test-shader/c": [11],
		"/(raw)/test-shader/gpt": [12],
		"/(web)/test": [~19,[2]],
		"/(web)/tryonline": [~20,[2]],
		"/(raw)/try": [13]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';